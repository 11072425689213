@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.forminput {
  position: relative;
  input {
    // border: 1px solid $secondary_border;
    width: 100%;
    padding: $font_xxs;
    border-radius: $border_xs;
    background-color: #FFF;
    color: $third_grey;
    font-size: $font_s;
    font-weight: 500;
    box-shadow: 0px 0px 4px 2px #D1D1D1;
    margin-top: 5px;
  }
  .input-icons {
    // border: 1px solid $secondary_border;
    width: 100%;
    padding: $font_xxs 0 $font_xxs 45px;
    border-radius: $border_xs;
    background-color: #FFF;
    color: $third_grey;
    font-size: $font_s;
    font-weight: 400;
    box-shadow: 0px 0px 4px 2px #D1D1D1;
    margin-top: 5px;
  }
  input:focus,
  .input-icons:focus {
    outline: none;
    box-shadow: 0px 0px 4px 2px #D1D1D1;
  }
  input::placeholder {
    font-size: $font_s;
    font-weight: 400;
  }
  .input-icons::placeholder {
    font-size: $font_s;
  }
  span {
    color: #343541;
    font-size: $font_s;
    font-weight: 500;
    background-color: $third_background;
    // padding: 0 3px;
    // position: absolute;
    // top: -10px;
    // left: $font_s;
  }
}

@media screen and (min-width: 280px) and (max-width:759px) {
  .forminput {
    position: relative;
    input {
      padding: $font_xs;
      border-radius: $border_xs;
      font-size: $font_s;
    }
    .input-icons {
      padding: $font_xs 0 $font_xs 45px;
      font-size: $font_s;
    }
    input::placeholder {
      font-size: $font_xs;
    }
    .input-icons::placeholder {
      font-size: $font_xs;
    }
    span {
      font-size: $font_s;
      // padding: 0 3px;
      // position: absolute;
      // top: -9px;
      // left: $font_s;
    }
  }
}
