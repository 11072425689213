@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.role {
  width: 100%;
  &_section {
    width: 100%;
    border-radius: $border_xs;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px 0;
    &_top-header {
      width: 52%;
      margin-bottom: 10px;
      h2{
        text-align: center;
        color: #343541;
        font-size: 24px;
        font-weight: 700;
      }
      h3 {
        font-size: 18px;
        font-weight: 500;
        color: #343541;
        text-align: center;
      }
    }
    &_center {
      width: 50%;
      background-color: $primary_background;
      border-radius: $border_xs;
      display: flex;
      // flex-direction: row-reverse;
      &_left {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 30px 20px;
        &_inputs {
          width: 90%;
          &_role {
            margin-bottom: 30px;
            @include flexcolumn;
            label {
              font-size: 16px;
              color: #343541;
              font-weight: 500;
              padding-bottom: 10px;
            }
            input {
              border: none;
              padding: $font_xxs;
              color: $secondary_grey;
              border-radius: $border_xs;
              background-color: #FFF;
              font-size: $font_s;
              font-weight: 500;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            }
            input:focus {
              outline: none;
              border: none;
            }
          }
          &_specialization {
            margin-bottom: $font_sm;
            @include flexcolumn;
            label {
              font-size: 16px;
              color: #343541;
              font-weight: 500;
              padding-bottom: 10px;
            }
            input {
              border: none;
              padding: $font_xxs;
              color: $secondary_grey;
              border-radius: $border_xs;
              background-color: #FFF;
              font-size: $font_s;
              font-weight: 500;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            }
            input:focus {
              outline: none;
              border: none;
            }
          }
        }
        &_buttons{
          width: 90%;
          display: flex;
          justify-content: space-between;
          &_left{
              width: 40%;
              .clear {
                border: $pixel_1 solid $secondary_border;
                margin: $font_xs 0;
                padding: $border_xs 0;
                border-radius: $border_xs;
                font-size: $font_sm;
                font-weight: 600;
                letter-spacing: 0.2px;
                text-align: center;
                cursor: pointer;
                color: $secondary_grey;
                width: 60%;
                background-color: $primary_background;
                transition: transform 0.1s ease;
              }
              .clear:active {
                transform: scale(0.95);
              }
          }
          &_right{
            width: 60%;
            display: flex;
            justify-content: space-between;
            .submit {
              width: 45%;
              margin: $font_xs 0;
              padding: $border_xs 0;
              border-radius: $border_xs;
              border: none;
              font-size: $font_sm;
              font-weight: 600;
              letter-spacing: 0.2px;
              text-align: center;
              cursor: pointer;
              color: $primary_white;
              background: #092B46;
              transition: transform 0.1s ease;
            }
            .submit:active {
              transform: scale(0.95);
            }
            .cancel {
              border: $pixel_1 solid $secondary_border;
              margin: $font_xs 0;
              padding: $border_xs 0;
              border-radius: $border_xs;
              font-size: $font_sm;
              font-weight: 600;
              letter-spacing: 0.2px;
              text-align: center;
              cursor: pointer;
              color: $secondary_grey;
              width: 45%;
              background-color: $primary_background;
              transition: transform 0.1s ease;
            }
            .cancel:active {
              transform: scale(0.95);
            }
            .save {
              width: 45%;
              margin: $font_xs 0;
              padding: $border_xs 0;
              border-radius: $border_xs;
              border: none;
              font-size: $font_sm;
              font-weight: 600;
              letter-spacing: 0.2px;
              text-align: center;
              cursor: pointer;
              color: $primary_white;
              background: #092B46;
              transition: transform 0.1s ease;
            }
            .save:active {
              transform: scale(0.95);
            }
          }
        }
      }
      // &_right{
      //   width: 50%;
      //   padding: 10px 0;
      //   &_instructions {
      //     display: flex;
      //     justify-content: center;
      //     flex-direction: column;
      //     width: 100%;
      //     padding: 0 20px;
      //     h4 {
      //       font-size: 20px;
      //       color: #343541;
      //       font-weight: 500;
      //       padding-bottom: $pixel_2;
      //       font-weight: 700;
      //     }
      //     ul{
      //       padding-left: 20px;
      //       li {
      //         font-size: 14px;
      //         color: #343541;
      //         font-weight: 500;
      //         margin-bottom: 10px;
      //         list-style-type: disc;
      //       }
      //      }
      //   }
      // }
      
    }
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (min-width: 280px) and (max-width: 759px) {
  .role {
    background-color: $primary_background;
    &_section {
      margin: 0;
      width: 100%;
      padding: 0 $font_sm;
      // height: 90vh;
      justify-content: center;
      // overflow-y: auto;
      &_top-header {
        width: 100%;
        padding: $font_s 0;
        margin-bottom: 25px;
        h2{
          font-size: 22px;
          text-align: left;
        }
        h3 {
          margin-bottom: 0;
          font-size: $font_ml;
          text-wrap: wrap;
          text-align: left;
        }
      }
     &_center{
      width: 100%;
      flex-direction: column;
      &_left {
        width: 100%;
        padding: 0;
        &_inputs {
          width: 100%;
          @include flexcolumn;
          row-gap: $border_xs;
          &_role {
            margin-bottom: $font_sm;
            label {
              font-size: $font_sm;
            }
            input {
              font-size: $font_sm;
            }
          }
          &_specialization {
            margin-bottom: $font_sm;
            label {
              font-size: $font_sm;
              padding-bottom: $pixel_2;
            }
            input {
              font-size: $font_sm;
            }
          }
        }
        &_buttons{
          width: 100%;
          &_left{
              width: 40%;
              .clear {
                font-size: $font_m;
              }
          }
          &_right{
            width: 60%;
            display: flex;
            justify-content: space-between;
            .submit {
              font-size: $font_m;
            }
            .cancel {
              font-size: $font_m;
            }
            .save {
              font-size: $font_m;
            }
          }
        }
        
      }
      &_right{
        width: 100%;
        &_instructions {
          margin-top: 25px;
          padding: 0;
          h4 {
            font-size: $font_m;
            color: $secondary_grey;
            font-weight: 700;
            padding-bottom: $pixel_2;
          }
          li {
            text-wrap: wrap;
            // padding: 0 $border_xs $pixel_5 $border_xs;
            font-size: $font_sm;
          }
        }
      }
     }
    }
    .loader-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

@media screen and (min-width: 760px) and (max-width: 1280px) {
  .role{
    height: calc(100vh - 62px);
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1281px) and (max-width: 1360px) {
  .role{
    height: calc(100vh - 62px);
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1361px) and (max-width: 1440px) {
  .role{
    height: calc(100vh - 62px);
    align-items: center;
    display: flex;
    &_section{
      &_center{
        padding: 30px 0;
      }
    }
  }
}

@media screen and (min-width: 1441px) and (max-width: 2560px) {
  .role{
    height: calc(100vh - 62px);
    align-items: center;
    display: flex;
    &_section{
      &_center{
        padding: 30px 0;
      }
    }
  }
}
