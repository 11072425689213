.custom-button {
    border: none;
    padding: 10px 25px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-family: "Unbounded", sans-serif;
  }
  
  .primary {
    background-color: #20E3D4;
    color: #0E1424;
    border-radius: 19px;
    padding: 10px 25px;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    font-family: "Unbounded", sans-serif;
  }
  
  .secondary {
    background-color: #20E3D4;
    color: #0E1424;
    border-radius: 5px;
    padding: 10px 15px;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    font-family: "Unbounded", sans-serif;
  }
  