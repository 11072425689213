@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.mainp {
  &_section {
    width: 100%;
    border-radius: $border_xs;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    &_center {
      width: 90%;
      &_header {
        margin-bottom: 20px;
        h2 {
          font-size: 24px;
          font-weight: 600;
          text-align: center;
        }
      }
      &_price {
        width: 100%;
        display: flex;
        justify-content: center;
        &_cont {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          &_sec {
            display: flex;
            justify-content: center;
            gap: 30px;
            &_plan {
              width: 320px;
              border-radius: 15px;
              border: 1px solid #20e3d4;
              background: #fff;
              padding: 20px;
              h2 {
                color: #808080;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 10px;
              }
              h3 {
                color: #808080;
                text-align: center;
                font-size: 25px;
                font-weight: 700;
              }
              .text {
                text-align: center;
                margin-bottom: 0;
                strong {
                  color: #0e1424;
                  text-align: center;
                  font-size: 35px;
                  font-weight: 700;
                }
                small {
                  color: #808080;
                  font-size: 20px;
                  font-weight: 700;
                }
              }
              h5 {
                text-align: center;
                font-weight: 600;
                font-size: 18px;
              }
              p {
                color: #666;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 20px;
                margin-top: 10px;
              }
              ul {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                gap: 10px;
                li {
                  display: flex;
                  gap: 10px;

                  .icon {
                    display: flex;
                    align-items: flex-start;
                    margin-top: 2px;
                  }
                  span {
                    color: #666;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                  }
                }
              }
              &_add {
                padding: 10px;
                margin-bottom: 10px;
                .text2 {
                  text-align: center;
                  strong {
                    color: #0e1424;
                    text-align: center;
                    font-size: 30px;
                    font-weight: 600;
                  }
                  small {
                    color: #808080;
                    font-size: 20px;
                    font-weight: 700;
                  }
                }
                h5 {
                  text-align: center;
                  font-weight: 600;
                  font-size: 18px;
                }
                p {
                  color: #666;
                  text-align: center;
                  font-size: 16px;
                  font-weight: 500;
                }
                ul {
                  li {
                    display: flex;
                    gap: 10px;
                    .icon {
                      display: flex;
                      align-items: flex-start;
                      margin-top: 2px;
                    }
                    span {
                      color: #666;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 20px;
                    }
                  }
                }
                &_button {
                  display: flex;
                  justify-content: center;
                  margin-top: 10px;
                }
              }
              &_add:nth-child(3) {
                border-top: 2px solid #c3c3c3;
              }
            }
          }
        }
      }
    }
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
.details {
  position: fixed;
  background-color: #00000021;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  &_popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 450px;
    &_header {
      display: flex;
      flex-direction: column;
      h2 {
        font-weight: 600;
        font-size: 20px;
        color: #425675;
        text-align: center;
      }
    }
    &_body {
      &_top {
        margin-bottom: 10px;
        padding-bottom: 4px;
        border-bottom: 1px solid #d5d5d5;
        h2 {
          font-size: 16px;
          color: #6a6b6a;
          font-weight: 600;
          text-transform: capitalize;
        }
        span {
          color: #707a91;
          font-size: 12px;
        }
      }
      &_center {
        display: flex;
        flex-direction: column;
        span {
          color: #707a91;
          font-size: 14px;
          padding-bottom: 6px;
        }
      }
    }
    &_buttom {
      display: flex;
      justify-content: center;
      gap: 20px;
      button {
        padding: 8px 20px;
        color: #fff;
        background: #104581;
        border-radius: 5px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        margin: 0 20px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .mainp {
    &_section {
      &_center {
        width: 90%;
        &_price {
          &_cont {
            &_sec {
              gap: 20px;
              flex-direction: column;
              &_plan {
                width: 300px;
                padding: 15px;
                h3 {
                  font-size: 22px;
                }
                .text {
                  strong {
                    font-size: 30px;
                  }
                }
                p {
                  color: #666;
                  text-align: center;
                  font-size: 16px;
                  font-weight: 500;
                  margin-bottom: 20px;
                }
                &_add {
                  .text2 {
                    text-align: center;
                    strong {
                      color: #0e1424;
                      text-align: center;
                      font-size: 30px;
                      font-weight: 600;
                    }
                    small {
                      color: #808080;
                      font-size: 20px;
                      font-weight: 700;
                    }
                  }
                  h5 {
                    text-align: center;
                    font-weight: 600;
                    font-size: 18px;
                  }
                  p {
                    color: #666;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
  .mainp {
    &_section {
      &_center {
        width: 95%;
        &_price {
          width: 100%;
          &_cont {
            width: 100%;
            &_sec {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 20px;
              &_plan {
                width: 320px;
                padding: 15px;
                h3 {
                  font-size: 22px;
                }
                .text {
                  text-align: center;
                  strong {
                    font-size: 30px;
                  }
                }
                p {
                  margin-bottom: 10px;
                }
                &_add {
                  .text2 {
                    text-align: center;
                    strong {
                      color: #0e1424;
                      text-align: center;
                      font-size: 30px;
                      font-weight: 600;
                    }
                    small {
                      color: #808080;
                      font-size: 20px;
                      font-weight: 700;
                    }
                  }
                  h5 {
                    text-align: center;
                    font-weight: 600;
                    font-size: 18px;
                  }
                  p {
                    color: #666;
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
