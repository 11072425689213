@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.header2 {
  background-color: $primary_background;
  width: 100%;
  display: flex;
  align-items: center;
  &_container {
    @include flexCenter;
    width: 100%;
    background-color: $primary_background;
    padding: $font_sm 0;
  }
  &_section {
    width: 94%;
    @include flexCenterBetween;
    &_left {
      width: 15%;
      @include flexCenterBetween;
      .logo {
        width: $height_s;
        height: $height_s;
        cursor: pointer;
      }
      .menu {
        width: $height_s;
        height: $height_xs;
      }
    }
    &_center {
      width: 60%;
      ul {
        display: flex;
        justify-content: space-around;
        // gap: 40px;
        li {
          @include flexColCenter;
          a {
            font-size: $font_s;
            font-weight: 600;
            color: $primary_grey;
            @include flexAcenter;
            margin-bottom: $border_xs;
          }
          .navLinkWrapper {
            width: $height_sm;
            border-bottom: $pixel_2 solid black;
          }
          a.active {
            color: #000;
          }
        }
      }
    }
    &_right {
      width: 20%;
      @include flexCenterBetween;
      &_free {
        span {
          font-size: $font_s;
          background-color: $primary_white;
          color: $secondary_grey;
          padding: $pixel_3 $font_sm;
          font-weight: 500;
        }
      }
      &_beta{
        span {
          font-size: $font_s;
          color: $primary_white;
          background: $primary_linear;
          padding: $pixel_3 $font_sm;
          font-weight: 500;
          border-radius: 2px;
        }
      }
      &_select{
        &_dropdown {
          position: relative;
          display: inline-block;
          padding: 3px 8px;
          cursor: pointer;
          border-radius: 5px;
          background-color: #FFF;
          // box-shadow: 0px 0px 4px 2px #D1D1D1;
          p {
            color: $secondary_grey;
            font-size: $font_s;
            font-weight: 500;
          }
        }
        .dropdown-content {
          display: none;
          position: absolute;
          background-color: #f9f9f9;
          min-width: 60px;
          border: 1px solid #ccc;
          border-radius: 5px;
          z-index: 1;
          top: 36px;
          left: 0;
        }
        .dropdown-content a {
          color: $primary_black;
          font-size: $font_s;
          font-weight: 500;
          padding: 4px 16px;
          text-decoration: none;
          display: block;
        }
        .dropdown-content a:hover {
          background-color: #f1f1f1;
        }
        .show {
          display: block;
        }
       }
      &_user {
        border: $pixel_2 solid $primary_border;
        border-radius: 50%;
        width: $height_s;
        height: $height_s;
        position: relative;
        .user {
          width: $height_s;
          height: $border_xl;
          cursor: pointer;
        }
        .useredit {
          position: absolute;
          width: $border_sm;
          height: $border_sm;
          top: $border_l;
          left: $border_l;
          cursor: pointer;
        }
        .logout_option {
          position: absolute;
          top: 100%;
          right: 1;
          background-color: $primary_white;
          border: 1px solid $third_border;
          padding: $border_xs;
          box-shadow: 0 2px $border_xxs rgba(0, 0, 0, 0.1);
          width: max-content;
          @include flexColStart;
          button {
            background: none;
            border: none;
            padding: $border_xs $border_sm;
            cursor: pointer;
            color: $fourth_grey;
            font-size: $font_s;
          }
        }
      }
    }
  }
  &_mobile-section {
    display: none;
  }
}

@media screen and (min-width: 280px) and (max-width: 900px) {
  .header2 {
    &_container {
      @include flexCenter;
      width: 100%;
    }
    &_section {
      display: none;
    }
    &_mobile-section {
      width: 85%;
      display: flex;
      justify-content: space-between;
      &_left {
        display: flex;
        align-items: center;
        .menu {
          width: $font_xl;
          height: 22px;
          margin-right: $font_xxs;
          cursor: pointer;
        }
        .logo {
          width: $font_l;
          height: $font_l;
        }
      }
      &_sidebar {
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
        height: 100vh;
        background-color: $primary_white;
        box-shadow: 0 0 $font_xxs rgba(0, 0, 0, 0.1);
        z-index: 1000;
        overflow-y: scroll;
        &_top {
          @include flexCenterBetween;
          border-bottom: 1px solid $third_border;
          padding: $border_l;
          width: 100%;
          &_user {
            border: 2px solid $primary_border;
            border-radius: 50%;
            width: $pixel_43;
            height: $pixel_43;
            position: relative;
            .user {
              width: $border_xxl;
              height: $border_xxl;
            }
            .logout_option {
              position: absolute;
              top: 100%;
              right: 1;
              background-color: $primary_white;
              border: 1px solid $third_border;
              box-shadow: 0 2px $border_xxs rgba(0, 0, 0, 0.1);
              width: max-content;
              @include flexColStart;
              button {
                background: none;
                border: none;
                padding: $border_xs $border_sm;
                cursor: pointer;
                color: $fourth_grey;
                font-size: $font_s;
              }
            }
            .useredit {
              display: none;
            }
          }
          &_username {
            h3 {
              font-size: $font_s;
              color: $fourth_grey;
              text-align: start;
            }
          }
          &_free {
            span {
              font-size: $font_s;
              background-color: $secondary_white;
              color: $secondary_grey;
              padding: $pixel_2 $font_sm;
              font-weight: 500;
            }
          }
        }
        &_center {
          padding: $border_l;
          margin-top: $font_m;
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
              margin-bottom: $border_xxl;
              a {
                font-size: $font_m;
                font-weight: 600;
                color: $primary_grey;
                @include flexAcenter;
                justify-content: space-between;
                h4 {
                  font-size: $font_s;
                  font-weight: 600;
                  color: $primary_grey;
                  width: 85%;
                }
              }
              a.active {
                color: $primary_black;
                h4 {
                  color: $primary_black;
                }
              }
            }
          }
        }
        &_bottom {
          padding: $border_l;
          &_logout {
            button {
              font-size: $font_s;
              background-color: $secondary_white;
              color: $secondary_grey;
              padding: $pixel_3 $font_sm;
              font-weight: 500;
              border: none;
            }
          }
        }
      }
    }
  }
}

// @media screen and (min-width: 550px) and (max-width: 1080px) {
//   .header2 {
//     &_container {
//       @include flexCenter;
//       width: 100%;
//       padding: 20px;
//     }
//     &_section {
//       display: none;
//     }
//     &_mobile-section {
//       width: 99%;
//       display: flex;
//       justify-content: space-between;
//       &_left {
//         display: flex;
//         align-items: center;
//         .menu {
//           width: $font_xl;
//           height: 30px;
//           margin-right: $font_xxs;
//         }
//         .logo {
//           width: $font_xl;
//           height: $font_xl;
//         }
//       }
//       &_sidebar {
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 300px;
//         height: 100vh;
//         background-color: $primary_white;
//         box-shadow: 0 0 $font_xxs rgba(0, 0, 0, 0.1);
//         z-index: 1000;
//         overflow-y: scroll;
//         &_top {
//           @include flexCenterBetween;
//           border-bottom: 1px solid $third_border;
//           padding: $border_l;
//           width: 100%;
//           &_user {
//             border: 2px solid $primary_border;
//             border-radius: 50%;
//             width: $pixel_43;
//             height: $pixel_43;
//             position: relative;
//             .user {
//               width: $border_xxl;
//               height: $border_xxl;
//             }
//             .logout_option {
//               position: absolute;
//               top: 100%;
//               right: 1;
//               background-color: $primary_white;
//               border: 1px solid $third_border;
//               box-shadow: 0 2px $border_xxs rgba(0, 0, 0, 0.1);
//               width: max-content;
//               @include flexColStart;
//               button {
//                 background: none;
//                 border: none;
//                 padding: $border_xs $border_sm;
//                 cursor: pointer;
//                 color: $fourth_grey;
//                 font-size: $font_s;
//               }
//             }
//             .useredit {
//               display: none;
//             }
//           }
//           &_username {
//             h3 {
//               font-size: $font_s;
//               color: $fourth_grey;
//               text-align: start;
//             }
//           }
//           &_free {
//             span {
//               font-size: $font_s;
//               background-color: $secondary_white;
//               color: $secondary_grey;
//               padding: $pixel_2 $font_sm;
//               font-weight: 500;
//             }
//           }
//         }
//         &_center {
//           padding: $border_l;
//           margin-top: $font_m;
//           ul {
//             list-style: none;
//             padding: 0;
//             margin: 0;
//             li {
//               margin-bottom: $border_xl;
//               a {
//                 font-size: $font_m;
//                 font-weight: 600;
//                 color: $primary_grey;
//                 @include flexAcenter;
//                 justify-content: space-between;
//                 h4 {
//                   font-size: $font_s;
//                   font-weight: 600;
//                   color: $primary_grey;
//                   width: 85%;
//                 }
//               }
//               a.active {
//                 color: $primary_black;
//                 h4 {
//                   color: $primary_black;
//                 }
//               }
//             }
//           }
//         }
//         &_bottom {
//           padding: $border_l;
//           &_logout {
//             button {
//               font-size: $font_s;
//               background-color: $secondary_white;
//               color: $secondary_grey;
//               padding: $pixel_3 $font_sm;
//               font-weight: 500;
//               border: none;
//             }
//           }
//         }
//       }
//     }
//   }
// }

// @media screen and (min-width: 1081px) and (max-width: 1280px) {
//   .header2{
//     &_section {
//       &_left{
//         width: 6%;
//       }
//       &_center {
//         width: 65%;
//         ul {
//           display: flex;
//           justify-content: space-between;
//           gap: 10px;
//         }
//       }
//       &_right {
//         width: 15%;
//       }
//     }
//   }
// }

// @media screen and (min-width: 1281px) and (max-width: 1360px) {
//   .header2{
//     &_section {
//       &_center {
//         width: 60%;
//         ul {
//           display: flex;
//           justify-content: center;
//           gap: 20px;
//         }
//       }
//       &_right {
//         width: 20%;
//       }
//     }
//   }
// }

// @media screen and (min-width: 1361px) and (max-width: 1440px) {
//   .header2{
//     &_section {
//       &_center {
//         width: 60%;
//         ul {
//           display: flex;
//           justify-content: center;
//           gap: 30px;
//         }
//       }
//       &_right {
//         width: 16%;
//       }
//     }
//   }
// }

// @media screen and (min-width: 1441px) and (max-width: 2560px) {
//   .header2{
//     &_section {
//       &_center {
//         width: 60%;
//         ul {
//           display: flex;
//           justify-content: center;
//           gap: 35px;
//         }
//       }
//       &_right {
//         width: 16%;
//       }
//     }
//   }
// }
