@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.login {
  &_container {
    width: 100%;
    @include flexCenter;
    background-color: $third_background;
  }
  &_section {
    width: 100%;
    @include flexbetween;
    &_left {
      width: 50%;
      @include flexCenter;
      background-color: $third_background;
      &_box {
        width: 50%;
        &_header {
          width: 100%;
          &_heading {
            width: 100%;
            @include flexAcenter;
            img {
              width: $border_ml;
              height: $border_ml;
              margin-right: $border_xxs;
            }
            h3 {
              color: $primary_black;
              font-size: $font_ml;
              font-weight: 700;
              margin: 0;
              padding: 0;
            }
          }
          &_content {
            @include flexJStart;
            margin-bottom: 15px;
            p {
              color: $primary_black;
              font-size: $font_s;
              font-weight: 500;
            }
          }
        }
        &_content {
          @include flexColCenter;
          &_inputs {
            width: 100%;
            @include flexcolumn;
            row-gap: 10px;
            &_select {
              h3 {
                color: #343541;
                font-size: $font_s;
                font-weight: 500;
                margin-bottom: 5px;
              }
              &_dropdown {
                position: relative;
                display: inline-block;
                padding: 10px;
                cursor: pointer;
                border-radius: 5px;
                width: 100%;
                background-color: #fff;
                box-shadow: 0px 0px 4px 2px #d1d1d1;
                p {
                  color: $primary_black;
                  font-size: 14px;
                  font-weight: 500;
                }
              }
              .dropdown-content {
                display: none;
                position: absolute;
                background-color: #f9f9f9;
                min-width: 180px;
                border: 1px solid #ccc;
                border-radius: 5px;
                z-index: 1;
                top: 36px;
              }
              .dropdown-content a {
                color: $primary_black;
                padding: 8px 16px;
                text-decoration: none;
                display: block;
                font-size: 14px;
                font-weight: 500;
              }
              .dropdown-content a:hover {
                background-color: #f1f1f1;
              }
              .show {
                display: block;
              }
            }
            &_email {
              position: relative;
              .emailicon {
                color: $third_grey;
                position: absolute;
                font-weight: 500;
                top: 44px;
                left: $font_sm;
                font-size: $font_xs;
              }
            }
            &_password {
              position: relative;
              .passwordicon {
                color: $third_grey;
                position: absolute;
                top: 42px;
                left: $font_sm;
                font-size: $font_s;
              }
              .eyeslashicon {
                color: $third_grey;
                position: absolute;
                top: 42px;
                right: $font_sm;
                font-size: $font_s;
                cursor: pointer;
              }
              .eyeicon {
                color: $third_grey;
                position: absolute;
                top: 42px;
                right: $font_sm;
                font-size: $font_s;
                cursor: pointer;
              }
            }
          }
          &_forgot {
            width: 100%;
            h3 {
              color: $secondary_grey;
              font-size: $font_xs;
              font-weight: 700;
              text-align: end;
              margin-top: $font_s;
              text-decoration: underline;
              cursor: pointer;
            }
          }
          &_button {
            width: 100%;
            margin: 20px 0 0 0;
            button {
              width: 100%;
              padding: $font_xs 0;
              border-radius: $border_xs;
              border: none;
              font-size: $font_s;
              font-weight: 600;
              letter-spacing: 0.2px;
              text-align: center;
              cursor: pointer;
              color: $primary_white;
              background: $primary_linear;
              transition: transform 0.1s ease;
            }
            button:active {
              transform: scale(0.95);
            }
          }
          &_border {
            width: 100%;
            @include flexAcenter;
            margin: $font_xxs 0;
            .line {
              width: 48%;
              border-bottom: 0.5px solid $primary_line;
            }
            h3 {
              color: $secondary_grey;
              font-size: $font_s;
              font-weight: 600;
              padding: 0 $border_xs;
            }
          }
          &_appstore {
            width: 100%;
            @include flexCenterBetween;
            border-radius: $border_xs;
            &_google {
              width: 45%;
              img {
                width: 100%;
                cursor: pointer;
              }
            }
            &_apple {
              width: 45%;
              img{
                width: 100%;
                cursor: pointer;
              }
            }
          }

          &_bottom {
            margin-top: $font_s;
            p {
              text-align: center;
              color: $secondary_grey;
              font-size: $font_s;
              font-weight: 600;
              span {
                color: $primary_text;
                cursor: pointer;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    &_right {
      width: 50%;
      display: flex;
      height: 100vh;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (min-width: 280px) and (max-width: 759px) {
  .login {
    background-color: $third_background;
    &_container {
      width: 100%;
      background-color: $third_background;
    }
    &_section {
      width: 100%;
      &_left {
        width: 100%;
        @include flexCenter;
        background-color: $primary_white;
        &_box {
          width: 100%;
          @include flexColCenter;
          &_header {
            width: 100%;
            background-color: $primary_white;
            padding-top: 25px;
            &_heading {
              width: 100%;
              @include flexColCenter;
              img {
                width: $border_xxxl;
                height: $border_xxxl;
                margin-right: 0;
              }
              h3 {
                margin-top: 20px;
                font-size: $font_m;
              }
            }
            &_content {
              @include flexCenter;
              margin-bottom: $border_xl;
              margin-top: $border_s;
              p {
                font-size: $font_xs;
              }
            }
          }
          &_content {
            width: 100%;
            background-color: $third_background;
            border-radius: $border_l $border_l 0 0;
            &_inputs {
              width: 85%;
              margin-top: 20px;
              @include flexcolumn;
              row-gap: 20px;
              &_email {
                position: relative;
                .emailicon {
                  top: 45px;
                  left: $font_sm;
                  font-size: $font_s;
                }
              }
              &_password {
                position: relative;
                .passwordicon {
                  top: 45px;
                  left: $font_sm;
                  font-size: $font_s;
                }
                .eyeslashicon {
                  top: 45px;
                  right: $font_sm;
                  font-size: $font_s;
                }
                .eyeicon {
                  top: 45px;
                  right: $font_sm;
                  font-size: $font_s;
                }
              }
            }
            &_forgot {
              width: 85%;
              h3 {
                font-size: $font_xs;
                font-weight: 600;
                margin-top: $font_s;
              }
            }
            &_border {
              width: 85%;
              @include flexAcenter;
              margin: $font_xxs 0;
              .line {
                width: 48%;
                border-bottom: 0.5px solid $primary_line;
              }
              h3 {
                font-size: $font_s;
              }
            }
            
            &_button {
              width: 85%;
              button {
                // margin: $font_l 0;
                width: 100%;
                font-size: $font_sm;
              }
              button:active {
                transform: scale(0.95); 
              }
            }
            // &_appstore {
            //   width: 85%;
            //   cursor: pointer;
            //   &_google {
            //     width: 12%;
            //     img {
            //       width: $height_xs;
            //       height: $height_xs;
            //     }
            //   }
            //   &_text {
            //     width: 88%;
            //   }
            // }
            &_appstore {
              width: 85%;
            }

            &_bottom {
              width: 85%;
              padding-bottom: $border_xxxl;
              p {
                font-size: $font_s;
                font-weight: 600;
                span {
                  color: $primary_text;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      &_right {
        display: none;
      }
    }
    .loader-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}
