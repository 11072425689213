@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
@import "./App/styles/variables.scss";
@import "./App/styles/mixins.scss";

.App {
  font-family: "Jost", sans-serif;
  height: 100vh;
  overflow: scroll;
  background-color: #fff;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
button,
td,
th,
strong,
small {
  margin: 0;
  font-family: "Jost", sans-serif;
}

ul {
  padding: 0;
  margin: 0;
  li {
    list-style-type: none;
    font-family: "Jost", sans-serif;
  }
}

a {
  text-decoration: none;
  font-family: "Jost", sans-serif;
}

button {
  cursor: pointer;
  font-family: "Jost", sans-serif;
}

::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
::-webkit-scrollbar-thumb {
  background: $secondary_background;
  border-radius: 0.3vw;
}
::-webkit-scrollbar-thumb:hover {
  background: $secondary_background;
}
::-webkit-scrollbar-track {
  border-radius: 0.5vw;
  background: #eeeeee;
}

.error {
  font-size: 10px;
  color: red;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 320px) and (max-width: 759px) {
  .error {
    font-size: 12px;
  }
}

.loader {
  color: $primary_linear;
}
