@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.layout {
    width: 100%;
    height: calc(100vh - 62px);
    padding-bottom: 20px;
    background-color: $primary_background;
    display: flex;
    justify-content: center;
    &_container {
      width: 95%;
      background-color: #fff;
      overflow: scroll;
    }
  }

  @media screen and (min-width: 280px) and (max-width: 759px) {
    .layout {
      width: 100%;
      height: calc(100vh - 62px);
      padding-bottom: 20px;
      background-color: #F7F7F8;
      display: flex;
      justify-content: center;
      &_container {
        width: 95%;
        background-color: #F7F7F8;
        overflow: scroll;
      }
    }
  }


  @media screen and (min-width: 760px) and (max-width: 1280px) {
    .layout{
      width: 100%;
      height: calc(100vh - 62px);
      padding-bottom: 20px;
      background-color: $primary_background;
      display: flex;
      justify-content: center;
    &_container {
      width: 95%;
      background-color: #fff;
      overflow: scroll;
      // display: flex;
      //   justify-content: center;
      //   align-items: center;
    }
  }
}

  @media screen and (min-width: 1281px) and (max-width: 1440px) {
    .layout {
      width: 100%;
      height: calc(100vh - 62px);
      padding-bottom: 20px;
      background-color: $primary_background;
      display: flex;
      justify-content: center;
      &_container {
        width: 95%;
        background-color: #fff;
        overflow: scroll;
        // display: flex;
        // justify-content: center;
        // align-items: center;
      }
    }
  }

  // @media screen and (min-width: 1361px) and (max-width: 1440px) {
  //   .layout {
  //     width: 100%;
  //     height: calc(100vh - 62px);
  //     background-color: $primary_background;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     &_container {
  //       width: 95%;
  //       background-color: #fff;
  //       overflow: scroll;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //     }
  //   }
  // }

  // @media screen and (min-width: 1441px) and (max-width: 2560px) {
  //   .layout {
  //     width: 100%;
  //     height: calc(100vh - 62px);
  //     background-color: $primary_background;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     &_container {
  //       width: 95%;
  //       background-color: #fff;
  //       overflow: scroll;
  //       display: flex;
  //       justify-content: center;
  //       align-items: center;
  //     }
  //   }
  // }
  