@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.signup {
  &_container {
    width: 100%;
    @include flexCenter;
    background-color: $third_background;
  }
  &_section {
    width: 100%;
    @include flexbetween;
    &_left {
      width: 50%;
      display: flex;
      height: 100vh;
      img {
        width: 100%;
        object-fit: cover;
      }
    }
    &_right {
      width: 50%;
      @include flexCenter;
      background-color: $third_background;
      &_box {
        width: 50%;
        &_header {
          width: 100%;
          &_heading {
            width: 100%;
            @include flexAcenter;
            img {
              width: $border_ml;
              height: $border_ml;
              margin-right: $border_xxs;
            }
            h3 {
              color: $primary_black;
              font-size: $font_ml;
              font-weight: 700;
              margin: 0;
              padding: 0;
            }
          }
          &_content {
            @include flexJStart;
            margin-bottom: 15px;
            p {
              color: $primary_black;
              font-size: $font_s;
              font-weight: 500;
            }
          }
        }
        &_content{
          @include flexColCenter;
          &_inputs {
            width: 100%;
            @include flexcolumn;
            row-gap: 10px;
            // &_email {
            //   .emailicon {
            //     color: $third_grey;
            //     position: absolute;
            //     font-weight: 500;
            //     top: 0.8vw;
            //     left: 1.2vw;
            //     font-size: $font_xs;
            //   }
            // }
            &_password {
              position: relative;
              // .passwordicon {
              //   color: $third_grey;
              //   position: absolute;
              //   top: 5.4vw;
              //   left: 1.2vw;
              //   font-size: $font_xs;
              // }
              .eyeslashicon {
                color: $third_grey;
                position: absolute;
                top: 42px;
                right: $font_s;
                font-size: $font_s;
                cursor: pointer;
              }
              .eyeicon {
                color: $third_grey;
                position: absolute;
                top: 42px;
                right: $font_s;
                font-size: $font_s;
                cursor: pointer;
              }
            }
          }
          &_checkbox {
            width: 100%;
            @include flexAcenter;
            margin-top: $font_s;
            input {
              margin-right: $border_s;
              width: $border_m;
            }
            p {
              color: $secondary_grey;
              font-size: $font_xs;
              font-weight: 500;
              span{
                color: $primary_text;
                cursor: pointer;
                font-weight: 700;
                &:hover{
                  text-decoration: underline;
                }
              }
                            
            }
          }
          
          &_button {
            width: 100%;
            margin: 20px 0 0 0;
            button {
              width: 100%;
              padding: $font_xs 0;
              border-radius: $border_xs;
              border: none;
              font-size: $font_s;
              font-weight: 600;
              letter-spacing: 0.2px;
              text-align: center;
              cursor: pointer;
              color: $primary_white;
              background: $primary_linear;
              transition: transform 0.1s ease;
            }
            button:active {
              transform: scale(0.95); 
            }
          }
          &_border {
            width: 100%;
            @include flexAcenter;
            margin: $border_s 0;
            .line {
              width: 48%;
              border-bottom: 0.5px solid $primary_line;
            }
            h3 {
              color: $secondary_grey;
              font-size: $font_s;
              font-weight: 600;
              padding: 0 $border_xs;
            }
          }
          &_appstore {
            width: 100%;
            @include flexCenterBetween;
            border-radius: $border_xs;
            margin: 0 0 $font_xs 0;
            &_google {
              width: 45%;
              img {
                width: 100%;
                cursor: pointer;
              }
            }
            &_apple {
              width: 45%;
              img{
                width: 100%;
                cursor: pointer;
              }
            }
          }
          
          &_bottom {
            p {
              text-align: center;
              color: $secondary_grey;
              font-size: $font_s;
              font-weight: 600;
              span {
                color: $primary_text;
                cursor: pointer;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (min-width:280px) and (max-width:759px) {
  .signup {
    background-color: $third_background;
    &_container {
      width: 100%;
      background-color: $third_background;
    }
    &_section {
      width: 100%;
      @include flexbetween;
      &_left {
        display: none;
      }
      &_right {
        width: 100%;
        @include flexCenter;
        background-color: $primary_white;
        &_box {
          width: 100%;
          &_header {
            width: 100%;
            background-color: $primary_white;
            padding-top: 25px;
            &_heading {
              width: 100%;
              @include flexColCenter;
              img {
                width: $border_xxxl;
                height: $border_xxxl;
                margin-right: 0;
              }
              h3 {
                margin-top: 20px;
                font-size: $font_m;
              }
            }
            &_content {
              @include flexCenter;
              margin-bottom: $border_xl;
              margin-top: $border_s;
              p {
                font-size: $font_xs;
              }
            }
          }
          &_content{
            width: 100%;
            background-color: $third_background;
            border-radius: $border_l $border_l 0 0;
            &_inputs {
              width: 85%;
              margin-top: 20px;
              @include flexcolumn;
              row-gap: 20px;
              &_password {
                position: relative;
                .eyeslashicon {
                  top: 45px;
                  right: $font_s;
                  font-size: $font_s;
                }
                .eyeicon {
                  top: 45px;
                  right: $font_s;
                  font-size: $font_s;
                }
              }
            }
            &_checkbox {
              width: 85%;
              @include flexAcenter;
              margin-top: $font_s;
              input {
                margin-right: $border_s;
                width: $border_m;
                height: $border_m;
              }
              p {
                font-size: $font_xs;
              }
            }
            &_border {
              width: 85%;
              @include flexAcenter;
              margin: $font_xxs 0;
              .line {
                width: 48%;
                border-bottom: 0.5px solid $primary_line;
              }
              h3 {
                font-size: $font_s;
              }
            }
            &_button {
              width: 85%;
              button {
                // margin: $font_l 0;
                width: 100%;
                font-size: $font_sm;
              }
              button:active {
                transform: scale(0.95); 
              }
            }
            &_appstore {
              width: 85%;
            }
            
            &_bottom {
              width: 85%;
              padding-bottom: $border_xxxl;
              p {
                font-size: $font_s;
                font-weight: 600;
                span {
                  color: $primary_text;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    .loader-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}
