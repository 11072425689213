@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.acc {
  width: 100%;
  display: flex;
  justify-content: center;
  &_sec {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    &_header {
      margin-bottom: 15px;
      h3 {
        font-weight: 700;
        font-size: 18px;
        color: #6b6a6a;
      }
    }
    &_content {
      margin-bottom: 10px;
      h4 {
        font-size: 16px;
        font-weight: 600;
        color: #6b6a6a;
      }
      ul {
        padding-left: 40px;
        li {
          list-style-type: disc;
          color: #6b6a6a;
        }
      }
    }
  }
}
