// get color names from here https://chir.ag/projects/name-that-color/#6195ED

// colors
$primary_white: #ffffff;
$secondary_white: #F4F4F4;
$primary_black: #343541;
$primary_background: #F7F7F8;
$secondary_background: #31b9b9;
$primary_grey: #6b6a6a;
$secondary_grey: #5c5b5b;
$third_grey: #8f8f8f;
$fourth_grey: #344054;
$primary_border: #1ad5d1;
$secondary_border: #989898;
$third_background:#F8F9FF;
$primary_line:#dedede;
$primary_text:#092B46;
$secondary_text:#C0C0C0;
$third_border: #C1C1C1;
$third_text: #4E4B4B;
$primary_linear: #092B46;
$primary_boxshadow: 3px 4px 20px 0px rgba(22, 194, 207, 0.4);
$primary_blue: rgb(90, 90, 255);

//Font-size
$font_xxl: 32px;
$font_xl: 28px;
$font_l: 24px;
$font_ml: 20px;
$font_m: 18px;
$font_sm: 16px;
$font_s: 14px;
$font_xs: 12px;
$font_xxs: 10px;

//Border-Radius
$border_xxxl: 50px;
$border_xxl: 40px;
$border_xl: 26px;
$border_l: 20px;
$border_ml: 18px;
$border_m: 15px;
$border_sm: 10px;
$border_s: 8px;
$border_xs: 5px;
$border_xxs: 4px;


$height_xxxl: 150px;
$height_xxl: 100px;
$height_sm: 50px;
$height_s: 30px;
$height_xs: 25px;


$pixel_140: 140px;
$pixel_120: 120px;
$pixel_66: 66px;
$pixel_60: 60px;
$pixel_48: 48px;
$pixel_45: 45px;
$pixel_43: 43px;
$pixel_36: 36px;
$pixel_13: 13px;
$pixel_6: 6px;
$pixel_5: 5px;
$pixel_3: 3px;
$pixel_2: 2px;
$pixel_1: 1px;


