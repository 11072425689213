.chatbot1 {
  display: flex;
  justify-content: center;
  height: 98%;
  &_main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_left {
      width: 70%;
      overflow-y: scroll;
      height: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      &_content{
        width: 90%;
        background-color: #FFF;
        h3{
          font-size: 20px;
          font-weight: 600;
          color: #6b6a6a;
          margin-bottom: 20px;
          text-decoration: underline;
        }
        p{
          font-size: 16px;
          font-weight: 500;
          color: #6b6a6a;
          padding: 10px;
        }
      }
      &_script{
        width: 90%;
        background-color: #000;
        code{
          color: #fff;
        }
      }
    }
    &_right {
      width: 40%;
    }
  }
}

@media screen and (min-width: 425px) and (max-width: 1024px) {
  .chatbot1 {
    &_main {
      height: 85%;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .chatbot1 {
    &_main {
      &_suggestions {
        flex-direction: column;
        width: 85%;
        & .tempdata {
          flex-direction: column;
        }
        &_box {
          width: 90%;
          border-radius: 8px;
          border: 1px solid #b1a9a9;
          padding: 1rem;
          // margin: 1rem;
          h5 {
            font-size: 18px;
            font-weight: 600;
            color: #716666;
            padding-bottom: 10px;
          }
          p {
            line-height: normal;
            font-size: 16px;
            font-weight: 400;
            color: #716666;
          }
        }
      }
      &_message {
        width: 85%;
        input {
          width: 100%;
          padding: 1.5rem 3rem;
          font-size: 18px;
        }
        textarea {
          width: 100%;
          padding: 1.5rem 3rem;
          font-size: 18px;
        }
      }
    }
  }
}
