.main {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin: 0;
    padding: 0;
    font-family: "Unbounded", sans-serif;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      font-family: "Unbounded", sans-serif;
    }
  }

  a {
    text-decoration: none;
    font-family: "Unbounded", sans-serif;
  }
  button {
    font-family: "Unbounded", sans-serif;
  }
  input{
    font-family: "Unbounded", sans-serif;
  }
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #141414;
    color: #fff;
    &_cont {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 40px 0;
      &_sec {
        width: 90%;
        display: flex;
        justify-content: space-between;
        &_left {
          width: 30%;
          display: flex;
          flex-direction: column;
          img {
            width: 70px;
            cursor: pointer;
          }
          p {
            margin: 20px 0;
            color: #c6cfda;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
          &_links {
            display: flex;
            gap: 20px;
            a {
              color: #fff;
              padding: 6px;
              text-align: center;
              display: flex;
              align-items: center;
              transition: transform 0.3s ease-in-out;
              font-size: 20px;
              border: 2px solid #dfdfdf;
              border-radius: 6px;
              &:hover a {
                transform: scale(1.3);
              }
            }
            a {
              transition: transform 0.3s;
              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }
        &_right {
          width: 65%;
          display: flex;
          justify-content: space-between;
          &_links {
            width: 20%;
            &_header {
              margin-bottom: 20px;
              h2 {
                font-size: 15px;
                font-weight: 700;
                color: #ffffff;
              }
            }
            &_content {
              ul {
                display: flex;
                flex-direction: column;
                gap: 15px;
                li {
                  text-decoration: none;
                  color: #c6cfda;
                  font-weight: 400;
                  font-size: 16px;
                  cursor: pointer;
                }
              }
            }
          }
          &_services {
            width: 30%;
            &_header {
              margin-bottom: 20px;
              h2 {
                font-size: 15px;
                font-weight: 700;
                color: #ffffff;
              }
            }
            &_content {
              ul {
                display: flex;
                flex-direction: column;
                gap: 15px;
                a {
                  text-decoration: none;
                  color: #c6cfda;
                  font-weight: 400;
                  font-size: 16px;
                }
                li {
                  text-decoration: none;
                  cursor: pointer;
                  color: #c6cfda;
                  font-weight: 400;
                  font-size: 16px;
                }
              }
            }
          }
          &_contact {
            width: 30%;
            &_header {
              margin-bottom: 20px;
              h2 {
                font-size: 15px;
                font-weight: 700;
                color: #ffffff;
              }
            }
            &_content {
              input {
                width: 100%;
                font-size: 14px;
                padding: 10px;
                font-weight: 500;
                color: #666666;
                border-radius: 6px;
                border: none;
                margin-bottom: 15px;
              }
              p {
                margin-top: 20px;
                color: #c6cfda;
                font-weight: 400;
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    &_bottom {
      width: 90%;
      background-color: #141414;
      text-align: center;
      padding: 15px 0;
      border-top: 1px solid #fff;
      p {
        font-size: 15px;
        font-weight: 400;
        color: #fff;
      }
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000062;
  z-index: 9999;
  &_container {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 1vw;
    width: 300px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    p {
      font-size: 16px;
      font-weight: 500;
      padding: 1vw;
      font-family: "Unbounded", sans-serif;
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1vw;
    margin-bottom: 10px;
    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      font-family: "Unbounded", sans-serif;
    }
    .close {
      cursor: pointer;
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    .footer {
      &_cont {
        width: 100%;
        padding: 20px 0;
        &_sec {
          width: 90%;
          flex-direction: column;
          &_left {
            width: 100%;
            display: flex;
            flex-direction: column;
            ul {
              flex-direction: column;
              gap: 10px;
            }
          }
          &_right {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            &_links {
              width: 100%;
              &_header {
                margin-bottom: 10px;
                margin-top: 30px;
                h2 {
                  font-size: 18px;
                  font-weight: 600;
                }
              }
              &_content {
                ul {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  a {
                    text-decoration: none;
                    color: #8c8c8c;
                    font-weight: 500;
                    font-size: 14px;
                  }
                }
              }
            }
            &_services {
              width: 100%;
              &_header {
                margin-bottom: 10px;
                margin-top: 30px;
                h2 {
                  font-size: 18px;
                  font-weight: 600;
                }
              }
              &_content {
                ul {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  a {
                    text-decoration: none;
                    color: #8c8c8c;
                    font-weight: 500;
                    font-size: 14px;
                  }
                }
              }
            }
            &_contact {
              width: 100%;
              &_header {
                margin-bottom: 20px;
                margin-top: 30px;
                h2 {
                  font-size: 18px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
      &_bottom {
        p {
          font-size: 10px;
        }
      }
    }
  }
}
