@import url("https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap");

.home {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin: 0;
    padding: 0;
    font-family: "Unbounded", sans-serif;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      font-family: "Unbounded", sans-serif;
    }
  }

  a {
    text-decoration: none;
    font-family: "Unbounded", sans-serif;
  }
  button {
    font-family: "Unbounded", sans-serif;
  }
  input {
    font-family: "Unbounded", sans-serif;
  }
  textarea {
    font-family: "Unbounded", sans-serif;
  }
  &_banner {
    // background-image: url(../../Assets/Images/mainBanner.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    height: calc(100vh - 90px);
    justify-content: center;
    &_cont {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      // margin-top: 130px;
      &_top {
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        h1 {
          font-size: 45px;
          color: #000;
          font-weight: 700;
          margin-bottom: 30px;
        }
        h2 {
          font-size: 40px;
          color: #20e3d4;
          font-weight: 700;
          margin-bottom: 30px;
        }
        p {
          font-size: 15px;
          color: #323232;
          font-weight: 400;
          text-align: center;
          line-height: 24px;
        }
        .btn {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
        }
      }
      &_bottom {
        display: flex;
        justify-content: center;
        img {
          width: 70%;
        }
      }
    }
  }
  &_about {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: 140px;
    padding: 30px 0;
    &_section {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        color: #20e3d4;
        font-size: 16px;
        font-weight: 500;
      }
      &_right {
        width: 70%;
        text-align: center;
        &_one {
          h2 {
            color: #2a2a2a;
            font-size: 32px;
            font-weight: 700;
            margin: 15px 0;
          }
          p {
            color: #808080;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 15px;
            line-height: 24px;
          }
        }
        &_two {
          margin-top: 40px;
          h2 {
            color: #2a2a2a;
            font-size: 32px;
            font-weight: 700;
            margin: 15px 0;
          }
          p {
            color: #808080;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 15px;
            line-height: 24px;
          }
        }
        &_learn {
          display: flex;
          justify-content: flex-start;
          width: fit-content;
        }
      }
    }
  }
  &_service {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0 80px 0;
    &_cont {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &_heading {
        width: 60%;
        text-align: center;
        span {
          color: #20e3d4;
          font-size: 16px;
          font-weight: 500;
        }
        h2 {
          color: #2a2a2a;
          font-size: 32px;
          font-weight: 700;
          margin: 20px 0;
        }
        p {
          color: #808080;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 20px;
          line-height: 22px;
        }
      }
      &_cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        &_card {
          background-color: #181d2d;
          border-radius: 10px;
          padding: 15px;
          img {
            width: 45px;
            height: auto;
          }
          h3 {
            font-size: 15px;
            font-weight: 700;
            color: #fff;
            margin: 20px 0;
            line-height: 22px;
          }
          p {
            color: #c6cfda;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
  &_key {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    background: #0e1424;
    &_section {
      width: 80%;
      display: flex;
      flex-direction: column;
      &_top {
        width: 60%;
        span {
          color: #20e3d4;
          font-size: 16px;
          font-weight: 500;
        }
        h2 {
          color: #fff;
          font-size: 32px;
          font-weight: 700;
          margin: 20px 0;
        }
        p {
          color: #808080;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 20px;
          line-height: 22px;
        }
      }
      &_tags {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        margin-top: 20px;
        &_tag {
          background-color: #fff;
          border-radius: 10px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          h4 {
            font-size: 18px;
            font-weight: 500;
            color: #0e1424;
          }
          p {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            color: #808080;
            margin-top: 20px;
          }
          .truncated {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-clamp: 4;
            box-orient: vertical;
          }
          .read-more {
            color: #20e3d4;
            cursor: pointer;
            font-size: 14px;
            display: block;
          }
          &:hover {
            background: linear-gradient(90deg, #000000 0%, #434343 100%);

            h4 {
              color: #fff;
            }
            p {
              color: #fff;
            }
          }
        }
      }
    }
  }
  &_benefits {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    background: #f8f9fa;
    &_section {
      width: 80%;
      display: flex;
      flex-direction: column;
      &_top {
        width: 60%;
        span {
          color: #20e3d4;
          font-size: 16px;
          font-weight: 500;
        }
        h2 {
          color: #fff;
          font-size: 32px;
          font-weight: 700;
          margin: 20px 0;
        }
        p {
          color: #808080;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 20px;
          margin-top: 20px;
          line-height: 22px;
        }
      }
      &_tags {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        margin-top: 20px;
        &_tag {
          background-color: #fff;
          border-radius: 10px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          h4 {
            font-size: 18px;
            font-weight: 500;
            color: #0e1424;
          }
          p {
            font-size: 14px;
            line-height: 22px;
            font-weight: 400;
            color: #808080;
            margin-top: 20px;
          }
          .truncated {
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-clamp: 4;
            box-orient: vertical;
          }
          .read-more {
            color: #20e3d4;
            cursor: pointer;
            font-size: 14px;
            display: block;
          }
          &:hover {
            background: linear-gradient(90deg, #20e3d4 0%, #0e1424 100%);
            h4 {
              color: #fff;
            }
            p {
              color: #fff;
            }
          }
        }
      }
    }
  }
  // &_new {
  //   padding: 20px 0;
  //   background: #dfebf7;
  //   &_benefits {
  //     width: 100%;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     padding: 10px 0;
  //     &_section {
  //       width: 80%;
  //       display: flex;
  //       flex-direction: column;
  //       &_top {
  //         width: 100%;
  //         span {
  //           color: #20e3d4;
  //           font-size: 16px;
  //           font-weight: 500;
  //         }
  //         h2 {
  //           color: #fff;
  //           font-size: 32px;
  //           font-weight: 700;
  //           margin: 20px 0;
  //         }
  //         p {
  //           color: #808080;
  //           font-size: 15px;
  //           font-weight: 400;
  //           margin-bottom: 20px;
  //           margin-top: 20px;
  //           line-height: 22px;
  //         }
  //       }
  //     }
  //   }
  // }
  &_llm {
    width: 100%;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    &_cont {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &_heading {
        width: 60%;
        text-align: center;
        span {
          color: #20e3d4;
          font-size: 16px;
          font-weight: 500;
        }
        h2 {
          color: #2a2a2a;
          font-size: 32px;
          font-weight: 700;
          margin: 20px 0;
        }
        p {
          color: #808080;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 20px;
          line-height: 22px;
        }
      }
      &_sec {
        display: flex;
        flex-direction: column;
        gap: 30px;
        &_card1 {
          display: flex;
          justify-content: space-between;
          background: #f0eefe;
          border-radius: 15px;
          padding: 50px;
          &_left {
            width: 65%;
            img {
              width: 50px;
              height: 50px;
            }
            h2 {
              color: #0e1424;
              font-size: 20px;
              font-weight: 700;
              margin: 10px 0;
            }
            p {
              color: #666;
              font-size: 15px;
              font-weight: 400;
              margin-bottom: 20px;
              line-height: 22px;
            }
            ul {
              display: flex;
              flex-direction: column;
              gap: 5px;
              li {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                .icon {
                  margin-top: 3px;
                }
                span {
                  color: #666;
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 24px;
                }
              }
            }
          }
          &_right {
            width: 35%;
            display: flex;
            align-items: center;
            img {
              width: 100%;
            }
          }
        }
        &_card2 {
          display: flex;
          justify-content: space-between;
          background: #fef9eb;
          border-radius: 15px;
          padding: 50px;
          &_left {
            width: 65%;
            img {
              width: 50px;
              height: 50px;
            }
            h2 {
              color: #0e1424;
              font-size: 20px;
              font-weight: 700;
              margin: 10px 0;
            }
            p {
              color: #666;
              font-size: 15px;
              font-weight: 400;
              margin-bottom: 20px;
              line-height: 22px;
            }
            ul {
              display: flex;
              flex-direction: column;
              gap: 5px;
              li {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                .icon {
                  margin-top: 3px;
                }
                span {
                  color: #666;
                  line-height: 24px;
                  font-size: 15px;
                  font-weight: 400;
                }
              }
            }
          }
          &_right {
            width: 35%;
            display: flex;
            align-items: center;
            img {
              width: 100%;
            }
          }
        }
        &_card3 {
          display: flex;
          justify-content: space-between;
          background: #ebf8fe;
          border-radius: 15px;
          padding: 50px;
          &_left {
            width: 65%;
            img {
              width: 50px;
              height: 50px;
            }
            h2 {
              color: #0e1424;
              font-size: 20px;
              font-weight: 700;
              margin: 10px 0;
            }
            p {
              color: #666;
              font-size: 15px;
              font-weight: 400;
              margin-bottom: 20px;
              line-height: 22px;
            }
            ul {
              display: flex;
              flex-direction: column;
              gap: 5px;
              li {
                display: flex;
                align-items: flex-start;
                gap: 10px;
                .icon {
                  margin-top: 3px;
                }
                span {
                  color: #666;
                  line-height: 24px;
                  font-size: 15px;
                  font-weight: 400;
                }
              }
            }
          }
          &_right {
            width: 35%;
            display: flex;
            align-items: center;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  &_price {
    width: 100%;
    padding: 60px 0;
    display: flex;
    justify-content: center;
    background: #f8f9fa;
    &_cont {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &_heading {
        width: 80%;
        text-align: center;
        span {
          color: #20e3d4;
          font-size: 16px;
          font-weight: 500;
        }
        h2 {
          color: #2a2a2a;
          font-size: 32px;
          font-weight: 700;
          margin: 20px 0;
        }
        p {
          color: #808080;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 20px;
          line-height: 22px;
        }
      }
      &_sec {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        &_plan {
          border-radius: 15px;
          border: 1px solid #20e3d4;
          background: #fff;
          padding: 30px;
          h3 {
            color: #808080;
            text-align: center;
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 10px;
          }
          .text {
            text-align: center;
            strong {
              color: #0e1424;
              text-align: center;
              font-size: 35px;
              font-weight: 700;
            }
            small {
              color: #808080;
              font-size: 20px;
              font-weight: 700;
            }
          }
          h5 {
            text-align: center;
          }
          p {
            color: #666;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            margin: 20px 0;
          }
          ul {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            gap: 10px;
            li {
              display: flex;
              gap: 10px;

              .icon {
                display: flex;
                align-items: flex-start;
                margin-top: 2px;
              }
              span {
                color: #666;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
              }
            }
          }
        }
        &_plan:nth-child(1),
        &_plan:nth-child(3) {
          margin-top: 40px;
        }
        &_plan:nth-child(2) {
          margin-bottom: 40px;
        }
      }
    }
  }
  &_test {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0 80px 0;
    background: #f8f9fa;
    &_section {
      width: 80%;
      display: flex;
      flex-direction: column;
      &_top {
        width: 60%;
        span {
          color: #20e3d4;
          font-size: 16px;
          font-weight: 500;
        }
        h2 {
          color: #2a2a2a;
          font-size: 32px;
          font-weight: 700;
          margin: 20px 0 30px 0;
        }
      }
      &_clients {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        &_card {
          &_top {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            border-radius: 10px;
            padding: 20px;
            position: relative;
            transition: background-color 0.3s ease;
            .qoute {
              fill: #20e3d4;
              transition: fill 0.3s ease;
            }
            p {
              color: #666;
              text-align: center;
              font-size: 16px;
              font-weight: 400;
              margin-top: 35px;
              line-height: 24px;
            }
            .arrow {
              width: 0;
              height: 0;
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              border-top: 20px solid #fff;
              position: absolute;
              bottom: -20px;
              left: 50%;
              transform: translateX(-50%);
              transition: border-top-color 0.3s ease;
            }
            &:hover {
              background-color: #20e3d4;
              .arrow {
                border-top-color: #20e3d4;
              }
              .qoute {
                fill: #ffffff;
              }
            }
          }
          &_bottom {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            margin-top: 30px;
            img {
              width: 50px;
              height: auto;
            }
            h3 {
              color: #808080;
              text-align: center;
              font-size: 18px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  &_event {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    background: #0e1424;
    &_section {
      width: 80%;
      display: flex;
      flex-direction: column;
      &_heading {
        width: 60%;
        span {
          color: #20e3d4;
          font-size: 16px;
          font-weight: 500;
        }
        h2 {
          color: #fff;
          font-size: 32px;
          font-weight: 700;
          margin: 20px 0 40px 0;
        }
      }
      &_cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        &_card {
          padding: 10px;
          border-radius: 15px;
          border: 1px solid #aaa;
          &_image {
            margin-bottom: 20px;
            img {
              width: 100%;
              transition: transform 0.5s ease, box-shadow 0.3s ease;
              aspect-ratio: 16/9;
            }
          }
          &_content {
            padding-left: 10px;
            h2 {
              color: #fff;
              font-size: 15px;
              font-weight: 700;
              line-height: 22px;
            }
            p {
              color: #c6cfda;
              font-size: 14px;
              font-weight: 400;
              margin: 20px 0;
              line-height: 22px;
            }
          }
          &:hover {
            img {
              transform: scale(1.02);
              box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
            }
          }
        }
      }
    }
  }
  &_contact {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    background: #f8f9fa;
    &_cont {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &_heading {
        width: 60%;
        text-align: center;
        p {
          color: #20e3d4;
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 20px;
        }
        h2 {
          color: #2a2a2a;
          font-size: 32px;
          font-weight: 700;
        }
      }
      &_form {
        width: 100%;
        &_inputs {
          margin: 20px 0;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;
          .data {
            display: flex;
            flex-direction: column;
            input {
              padding: 10px;
              border-radius: 5px;
              border: 1px solid #20e3d4;
              background: #f8f9fa;
              margin-bottom: 10px;
            }
            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
              -webkit-appearance: none;
            }
          }
        }
        &_text {
          textarea {
            width: 100%;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #20e3d4;
            background: #f8f9fa;
            resize: none;
          }
        }
        &_bottom {
          display: flex;
          justify-content: flex-end;
          margin-top: 20px;
        }
      }
    }
  }
}
.popup {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00000062;
  z-index: 9999;
  &_container {
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 1vw;
    width: 300px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    p {
      font-size: 16px;
      font-weight: 500;
      padding: 1vw;
      font-family: "Unbounded", sans-serif;
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 1vw;
    margin-bottom: 10px;
    h2 {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      font-family: "Unbounded", sans-serif;
    }
    .close {
      cursor: pointer;
      font-size: 22px;
    }
  }
}

@media screen and (max-width: 767px) {
  .home {
    &_banner {
      height: fit-content;
      &_cont {
        width: 90%;
        padding: 50px 0;
        margin-top: 0;
        &_top {
          width: 100%;
          h1 {
            font-size: 30px;
            text-align: center;
          }
          h2 {
            font-size: 30px;
            text-align: center;
          }
          p {
            font-size: 15px;
          }
        }
        &_bottom {
          display: flex;
          justify-content: center;
          img {
            width: 100%;
          }
        }
      }
    }
    &_about {
      padding-top: 30px;
      padding-bottom: 30px;
      &_section {
        width: 90%;
        flex-direction: column;
        &_left {
          width: 100%;
          img {
            width: 100%;
          }
        }
        &_right {
          width: 100%;
          margin-top: 30px;
          span {
            font-size: 16px;
          }
          h2 {
            font-size: 24px;
          }
          p {
            font-size: 14px;
          }
          &_learn {
            width: 100%;
            justify-content: flex-end;
          }
        }
      }
    }
    &_service {
      padding: 20px 0 50px 0;
      &_cont {
        width: 90%;
        &_heading {
          width: 100%;
          text-align: left;
          h2 {
            font-size: 24px;
          }
          p {
            font-size: 14px;
          }
        }
        &_cards {
          grid-template-columns: repeat(1, 1fr);
          &_card {
            h3 {
              font-size: 16px;
            }
          }
        }
      }
    }
    &_key {
      padding: 60px 0;
      &_section {
        width: 90%;
        &_top {
          width: 100%;
          h2 {
            font-size: 24px;
          }
          p {
            font-size: 14px;
          }
        }
        &_tags {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    &_benefits {
      padding: 60px 0;
      &_section {
        width: 90%;
        &_top {
          width: 100%;
          h2 {
            font-size: 24px;
          }
          p {
            font-size: 14px;
          }
        }
        &_tags {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    // &_new {
    //   padding: 20px 0;
    //   background: #dfebf7;
    //   &_benefits {
    //     padding: 0;
    //     &_section {
    //       width: 90%;
    //       &_top {
    //         width: 100%;
    //         h2 {
    //           font-size: 24px;
    //         }
    //         p {
    //           font-size: 14px;
    //         }
    //       }
    //       &_tags {
    //         grid-template-columns: repeat(1, 1fr);
    //       }
    //     }
    //   }
    // }
    &_llm {
      padding: 40px 0;
      &_cont {
        width: 90%;
        &_heading {
          width: 100%;
          text-align: left;
          h2 {
            font-size: 24px;
          }
          p {
            font-size: 14px;
          }
        }
        &_sec {
          &_card1 {
            flex-direction: column;
            padding: 15px;
            &_left {
              width: 100%;
              p {
                font-size: 14px;
              }
              ul {
                gap: 10px;
                li {
                  align-items: start;
                  gap: 10px;
                  .icon {
                    display: flex;
                    align-items: flex-start;
                    margin-top: 2px;
                  }
                  span {
                    text-align: left;
                    font-size: 14px;
                  }
                }
              }
            }
            &_right {
              width: 100%;
              margin-top: 20px;
              img {
                width: 100%;
              }
            }
          }
          &_card2 {
            flex-direction: column;
            padding: 15px;
            &_left {
              width: 100%;
              p {
                font-size: 14px;
              }
              ul {
                gap: 10px;
                li {
                  align-items: start;
                  gap: 10px;
                  .icon {
                    display: flex;
                    align-items: flex-start;
                    margin-top: 2px;
                  }
                  span {
                    text-align: left;
                    font-size: 14px;
                  }
                }
              }
            }
            &_right {
              width: 100%;
              margin-top: 20px;
              img {
                width: 100%;
              }
            }
          }
          &_card3 {
            flex-direction: column;
            padding: 15px;
            &_left {
              width: 100%;
              p {
                font-size: 14px;
              }
              ul {
                gap: 10px;
                li {
                  align-items: start;
                  gap: 10px;
                  .icon {
                    display: flex;
                    align-items: flex-start;
                    margin-top: 2px;
                  }
                  span {
                    text-align: left;
                    font-size: 14px;
                  }
                }
              }
            }
            &_right {
              width: 100%;
              margin-top: 20px;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
    &_price {
      padding: 60px 0;
      &_cont {
        width: 90%;
        &_heading {
          width: 100%;
          text-align: left;
          h2 {
            font-size: 24px;
          }
          p {
            font-size: 14px;
          }
        }
        &_sec {
          grid-template-columns: repeat(1, 1fr);
          gap: 30px;
          &_plan {
            padding: 15px;
            h3 {
              font-size: 24px;
            }
            .text {
              text-align: center;
              strong {
                font-size: 30px;
              }
              small {
                font-size: 18px;
              }
            }
            p {
              font-size: 14px;
            }
          }
          &_plan:nth-child(1),
          &_plan:nth-child(3) {
            margin-top: 0;
          }
          &_plan:nth-child(2) {
            margin-bottom: 0;
          }
        }
      }
    }
    &_test {
      padding: 30px 0 30px 0;
      &_section {
        width: 90%;
        &_top {
          width: 100%;
          h2 {
            font-size: 24px;
          }
        }
        &_clients {
          display: grid;
          grid-template-columns: repeat(1, 1fr);
          gap: 20px;
          &_card {
            &_top {
              padding: 20px;
              p {
                font-size: 14px;
              }
            }
            &_bottom {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    &_event {
      padding: 50px 0;
      &_section {
        width: 90%;
        &_heading {
          width: 100%;
          h2 {
            font-size: 24px;
            margin: 20px 0 30px 0;
          }
        }
        &_cards {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
    &_contact {
      padding: 30px 0;
      &_cont {
        width: 90%;
        &_heading {
          width: 100%;
          text-align: left;
          h2 {
            font-size: 24px;
          }
        }
        &_form {
          width: 100%;
          &_inputs {
            grid-template-columns: repeat(1, 1fr);
          }
          &_bottom {
            display: flex;
            justify-content: center;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .home {
    &_banner {
      height: 100vh;
      &_cont {
        width: 70%;
        margin-top: 60px;
        &_top {
          width: 90%;
          h1 {
            font-size: 34px;
          }
          h2 {
            font-size: 34px;
          }
        }
      }
    }
    &_about {
      padding-top: 80px;
      &_section {
        width: 90%;
      }
    }
    &_service {
      &_cont {
        width: 90%;
      }
    }
    &_key {
      &_section {
        width: 90%;
        &_tags {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    &_benefits {
      &_section {
        width: 90%;
        &_tags {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    &_llm {
      &_cont {
        width: 90%;
      }
    }
    &_price {
      padding: 60px 0 30px 0;
      &_cont {
        width: 90%;
        &_sec {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          &_plan {
            width: 300px;
          }
          &_plan:nth-child(1),
          &_plan:nth-child(3) {
            margin-top: 0px;
          }
          &_plan:nth-child(2) {
            margin-bottom: 0px;
          }
        }
      }
    }
    &_test {
      &_section {
        width: 90%;
        &_clients {
          &_card {
            &_top {
              padding: 20px 5px;
            }
          }
        }
      }
    }
    &_event {
      &_section {
        width: 90%;
      }
    }
    &_contact {
      &_cont {
        width: 90%;
      }
    }
  }
}
