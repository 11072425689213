// VoiceAssistance.scss
@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.va {
  width: 100%;
  &_section {
    width: 100%;
    border-radius: $border_xs;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    &_center {
      width: 100%;
      @include flexCenter;
      &_box {
        width: 40%;
        padding: 12px 0;
        @include flexColCenter;
        &_file {
          width: 100%;
          @include flexCenter;
          // background-color: $primary_background;
          background-image: url("../../Assets/Images/VABcg.png");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          border-radius: $border_xs;
          padding: $border_xl 0;
          margin-bottom: $font_s;
          height: 33vh;
          &_choose {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            .voice {
              background-color: white;
              box-shadow: 0px  0px 4px 1px #D1D1D1;
              border-radius: 50%;
              width: 100px;
              height: 100px;
              padding: 5px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
        &_instructions {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          padding: 20px;
          border-radius: 10px;
          background-color: $primary_background;
          h4 {
            font-size: 20px;
            color: #343541;
            font-weight: 500;
            padding-bottom: $pixel_2;
            font-weight: 700;
          }
         ul{
          padding-left: 20px;
          li {
            font-size: 14px;
            color: #343541;
            font-weight: 500;
            margin-bottom: 10px;
            list-style-type: disc;
          }
         }
        }
        // &_note {
        //   display: flex;
        //   justify-content: center;
        //   flex-direction: column;
        //   width: 100%;
        //   padding: 0 $border_l;
        //   h4 {
        //     font-size: $font_sm;
        //     color: $secondary_grey;
        //     font-weight: 500;
        //     padding-bottom: $pixel_2;
        //   }
        //   p {
        //     font-size: $font_s;
        //     color: $secondary_grey;
        //     font-weight: 400;
        //   }
        // }
      }
    }
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 1;
  }
  .loader-container2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (min-width: 280px) and (max-width: 759px) {
  .va {
    &_section {
      padding: 16px 0;
      &_center {
        width: 100%;
        @include flexCenter;
        &_box {
          width: 100%;
          padding: 14px 0;
          &_instructions {
            border: 1px solid gray;
            h4 {
              font-size: $font_m;
              font-weight: 700;
              padding-bottom: $pixel_2;
            }
            li {
              padding: 0 $border_xs $pixel_5 $border_xs;
              font-size: $font_m;
            }
          }
          &_note {
            width: 90%;
            padding: 0;
            h4 {
              padding-bottom: $pixel_2;
            }
            p {
              text-align: justify;
              font-size: $font_s;
            }
          }
        }
      }
    }
    .loader-container2 {
      top: 0;
      left: 0;
    }
  }
}

@media screen and (min-width: 760px) and (max-width: 1280px) {
  // .va{
  //   height: calc(100vh - 62px);
  //   align-items: center;
  //   display: flex;
  //   justify-content: center;
  // }
}

@media screen and (min-width: 1281px) and (max-width: 1360px) {
  .va{
    height: calc(100vh - 62px);
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1361px) and (max-width: 1440px) {
  .va{
    height: calc(100vh - 62px);
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1441px) and (max-width: 2560px) {
  .va{
    height: calc(100vh - 62px);
    align-items: center;
    display: flex;
  }
}
