.confirm {
  position: fixed;
  background-color: #00000021;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  &__dialog {
    background-color: white;
    box-shadow: 0 4px 16px 0 #ffffff40;
    padding: 2vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    color: rgb(0, 0, 0);

    &__button {
      min-width: 8vw;
      padding: 1vw;
      border: none;
      &--cancel {
        background: rgb(158, 158, 158);
        padding: 1.4vw;
      }

      // &--cancel:active {
      //   box-shadow: inset -6px -6px 6px 0 grey, inset 6px 6px 8px 0 black,
      //     inset 6px 6px 8px 0 black, inset -6px -6px 6px 0 grey;
      //   border-style: solid;
      //   border-width: 1px;
      //   border-image-source: linear-gradient(119deg, black 0%, black 98%);
      //   border-image-slice: 1;
      //   background: linear-gradient(to bottom, black, black),
      //     linear-gradient(119deg, black 0%, black 98%);
      //   background-origin: border-box;
      //   background-clip: content-box, border-box;
      // }
    }

    &__button:focus {
      outline: none;
    }

    &__content {
      padding: 0 0;
    }

    &__description {
      font-size: 1.4vw;
      color: #5c5b5b;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      margin: 0;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
    }

    &__footer {
      justify-content: space-evenly;
      display: flex;
    }
  }
}

@media screen and (min-width: 280px) and (max-width: 760px) {
  .confirm {
    &__dialog {
      width: 85%;
      padding: 6vw 3vw;
      &__content {
        p {
          font-size: 30px;
        }
      }
      &__button {
        width: fit-content !important;
        padding: 10px 20px !important;
        font-size: 20px !important;
      }
    }
  }
}
