.chatbot {
  display: flex;
  background-color: #FFF;
  &_main {
    display: flex;
    flex-direction: column;
    height: 98%;
    width: 100%;
    align-items: center;
    &_container {
      width: 100%;
      overflow-y: scroll;
      height: -webkit-fill-available;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
    }
    &_suggestions {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      width: 85%;
      overflow-y: scroll;
      flex-direction: column;
      .qanda {
        margin-bottom: 1.5vw;
      }
      .qanda:last-child {
        margin-bottom: 0vw;
      }
      .question {
        font-size: 1.2vw;
        font-weight: 500;
        margin-bottom: 0.3vw;
      }
      .answer {
        font-size: 0.9vw;
        color: #716666;
      }
      .tempdata {
        display: flex;
        justify-content: center;
      }
      &_box {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #b1a9a9;
        margin: 12px 0;
        padding: 10px;
        h5 {
          font-size: 16px;
          font-weight: 600;
          color: #716666;
          padding-bottom: 5px;
        }
        p {
          line-height: normal;
          font-size: 14px;
          font-weight: 400;
          color: #716666;
        }
      }
    }
    .loader {
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      -webkit-animation: spin 3s linear infinite;
      animation: spin 3s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    &_message {
      width: 90%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      input {
        width: 100%;
        border-radius: 8px;
        border: 1px solid #b1a9a9;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
        padding: 1.5rem 3rem;
        font-size: 18px;
        word-wrap: break-word;
      }
      textarea {
        width: 100%;
        height: 40px;
        border-radius: 8px;
        border: 1px solid #b1a9a9;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25);
        padding: 5px 45px;
        font-size: 18px;
        word-wrap: break-word;
        overflow-y: auto;
        color: #6b6a6a;
        
      }
      .chatbotmsgimg {
        position: absolute;
        left: 1rem;
      }
      .chatbotsendimg {
        cursor: pointer;
        position: absolute;
        right: 1.4rem;
      }
    }
  }
  &_poweredby {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 12px;
      width: 12px;
      margin-right: 10px;
    }
    p {
      font-size: 10px;
      a {
        font-weight: bold;
      }
    }
  }
}


@media screen and (min-width: 320px) and (max-width: 767px) {
  .chatbot {
    &_main {
      &_suggestions {
        flex-direction: column;
        width: 85%;
        & .tempdata {
          flex-direction: column;
        }
        &_box {
          width: 90%;
          border-radius: 8px;
          border: 1px solid #b1a9a9;
          padding: 1rem;
          // margin: 1rem;
          h5 {
            font-size: 18px;
            font-weight: 600;
            color: #716666;
            padding-bottom: 10px;
          }
          p {
            line-height: normal;
            font-size: 16px;
            font-weight: 400;
            color: #716666;
          }
        }
      }
      &_message {
        width: 85%;
        input {
          width: 100%;
          padding: 1.5rem 3rem;
          font-size: 18px;
        }
        textarea {
          width: 100%;
          padding: 1.5rem 3rem;
          font-size: 18px;
        }
      }
    }
    &_poweredby {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 12px;
        width: 12px;
        margin-right: 10px;
      }
      p {
        font-size: 10px;
        a {
          font-weight: bold;
        }
      }
    }
  }
}
