@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.button {
  margin-top: $font_l;
  width: 30%;
  outline: none;
  cursor: pointer;
  color: white;
  font-size: $font_xs;
  font-weight: 600;
  line-height: 1.21;
  padding: $font_xs 0;
  border-radius: 34px;
  color: $secondary_grey;
  border: 2px solid $secondary_grey;
  background-color: $primary_background;

  // &__disabled {
  //   box-shadow: 6px 6px 9px 0 #42597a, -1px -1px 4px 0 #42597a;
  //   background: #42597a;
  //   border: 1px solid #42597a;
  //   cursor: not-allowed;
  //   opacity: 0.5;
  //   color: white;
  // }
}

.button:active {
  box-shadow: inset -6px -6px 6px 0 $primary_background,
    inset 6px 6px 8px 0 $primary_background,
    inset 6px 6px 8px 0 $primary_background inset -6px -6px 6px 0 $primary_background !important;
  border-style: solid;
  border-width: 1px;
  border-image-slice: 1;
  background: $primary_background;
  background-origin: border-box;
}