.chatbot {
  position: fixed;
  bottom: 25px;
  right: 35px;
  cursor: pointer;
  font-size: 28px;
  img{
    width: 20px;
    height: 20px;
  }
  &_modal {
    display: block;
    position: fixed;
    bottom: 50px;
    right: 20px;
    width: 320px;
    height: 560px;
    border: 1px solid #ccc;
    z-index: 1000;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .chatbot {
    font-size: 18px;
    bottom: 20px;
    &_modal {
      bottom: 60px;
      right: 10px;
      width: 300px;
      height: 420px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .chatbot {
    font-size: 18px;
    &_modal {
      bottom: 50px;
      right: 30px;
      width: 300px;
      height: 440px;
    }
  }
}
