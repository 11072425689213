@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.dashboard {
  background-color: $primary_background;
  height: calc(100vh - 56px);
  width: 100%;
  @include flexCenter;
  &_container {
    width: 90%;
    @include flexCenter;
    background-color: $primary_white;
    margin-bottom: $border_l;
    height: -webkit-fill-available;
    ::-webkit-scrollbar {
      width: $border_sm !important;
      height: 0px !important;
    }
  }
  &_section {
    margin: 0 $height_s 0 0;
    width: 100%;
    height: calc(100% - $font_xxl);
    border-radius: $border_xs;
    padding: $font_sm;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    &_center {
      width: 90%;
      @include flexCenter;
      &_header{
        h1{
          font-size: $font_sm;
          text-align: center;
        }
      }
    }
  }
}