.header {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin: 0;
    padding: 0;
    font-family: "Unbounded", sans-serif;
  }

  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      font-family: "Unbounded", sans-serif;
    }
  }

  a {
    text-decoration: none;
    font-family: "Unbounded", sans-serif;
  }
  button {
    font-family: "Unbounded", sans-serif;
  }
  .nav {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: transparent;
    padding: 10px 0;
    background-color: #FFF;
    &_container {
      width: 85%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_left {
        width: 15%;
        img {
          width: 70px;
          cursor: pointer;
        }
      }
      &_center {
        width: 55%;
        ul {
          display: flex;
          justify-content: space-between;
          li {
            font-weight: 700;
            color: #0e1424;
            transition: color 0.5s ease;
            cursor: pointer;
            font-size: 15px;
            text-decoration: none;
          }
        }
      }
      &_right {
        width: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        &_btn1 {
          position: relative;
          .dropdown1 {
            position: absolute;
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            top: 50px;
            right: 10px;
            width: 210px;
            ul {
              list-style-type: none;
              padding: 0;
              margin: 0;
              li {
                padding: 10px 20px;
                cursor: pointer;
                &:hover {
                  background-color: #f1f1f1;
                }
              }
            }
          }
        }
        &_btn2 {
          position: relative;
          .dropdown2 {
            position: absolute;
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            top: 50px;
            right: 10px;
            width: 210px;
            ul {
              list-style-type: none;
              padding: 0;
              margin: 0;

              li {
                padding: 10px 20px;
                cursor: pointer;
                &:hover {
                  background-color: #f1f1f1;
                }
              }
            }
          }
        }
      }
    }
  }

  .nav.scrolled {
    position: fixed;
    background-color: white;
    padding: 10px 0;
    top: 0;
    transition: background-color 0.5s ease, box-shadow 0.5s ease;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10000;
    .nav_container {
      &_left {
        img {
          width: 60px;
        }
      }
      &_center {
        ul {
          li {
            color: #000;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header {
    .nav {
      &_container {
        width: 95%;
        &_left {
          width: fit-content;
          img {
            width: 45px;
          }
        }
        &_center {
          display: none;
        }
        &_right {
          width: fit-content;
        }
      }
    }
    .nav.scrolled {
      .nav_container {
        &_left {
          width: 40%;
          img {
            width: 45px;
          }
        }
        &_center {
          display: none;
        }
        &_right {
          width: fit-content;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1080px) {
  .header {
    .nav {
      &_container {
        width: 95%;
        &_left {
          width: fit-content;
          img {
            width: 60px;
          }
        }
        &_center {
          width: 60%;
          ul {
            gap: 8px;
            a {
              font-size: 12px;
            }
          }
        }
        &_right {
          width: fit-content;
        }
      }
    }
    .nav.scrolled {
      .nav_container {
        width: 95%;
        &_left {
          width: fit-content;
          img {
            width: 60px;
          }
        }
        &_center {
          width: fit-content;
          ul {
            li {
              color: #000;
            }
          }
        }
        &_right {
          width: fit-content;
        }
      }
    }
  }
}
