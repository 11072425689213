@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.upload {
  width: 100%;
  &_section {
    width: 100%;
    border-radius: $border_xs;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    &_center {
      width: 85%;
      display: flex;
      justify-content: space-between;
      background-color: #F7F7F8;
      padding: 20px 30px;
      &_left {
        width: 46%;
        @include flexColCenter;
        &_text{
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 10px;
          h3{
            font-size: 16px;
            font-weight: 500;
            color: #343541;
          }
        }
        &_file {
          width: 100%;
          @include flexCenter;
          background-color: #FFF;
          border-radius: $border_xs;
          padding: $border_xl 0;
          margin-bottom: $font_s;
          box-shadow: 0px 0px 4px 1px #dddddd;
          &_choose {
            width: 85%;
            height: 140px;
            @include flexColCenter;
            padding: $font_l;
            border: 2px dashed $secondary_border;
            img {
              width: $pixel_45;
              height: $pixel_45;
              cursor: pointer;
            }
            h3 {
              padding-top: $font_xs;
              font-size: $font_s;
              color: $third_grey;
              font-weight: 600;
            }
          }
        }
        &_buttons {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: $font_s;
          .cancel {
            border: $pixel_2 solid $secondary_border;
            margin: $font_xs 0;
            padding: $border_s 0;
            border-radius: $border_xs;
            font-size: $font_sm;
            font-weight: 600;
            letter-spacing: 0.2px;
            text-align: center;
            cursor: pointer;
            color: $secondary_grey;
            width: 45%;
            background-color: $primary_background;
            transition: transform 0.1s ease;
          }
          .cancel:active {
            transform: scale(0.95);
          }
          .save {
            width: 45%;
            margin: $font_xs 0;
            padding: $font_xxs 0;
            border-radius: $border_xs;
            border: none;
            font-size: $font_sm;
            font-weight: 600;
            letter-spacing: 0.2px;
            text-align: center;
            cursor: pointer;
            color: $primary_white;
            background: $primary_linear;
            opacity: 1;
            transition: transform 0.1s ease;
          }
          .save:active {
            transform: scale(0.95);
          }
        }
        &_recent{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 20px 0 10px 0;
          border-top: 1px solid #343541;
          opacity: 0.6;
          h3{
            color: #343541;
            opacity: 0.8;
            font-weight: 500;
            font-size: 12px;
            padding-left: 5px;
          }
        }
        &_content {
          width: 100%;
          @include flexColCenter;
          border-radius: $border_xs;
          overflow-y: auto;
          &_print {
            width: 100%;
            @include flexCenterBetween;
            border: 1px solid $third_text;
            border-radius: $border_sm;
            background-color: #FFF;
            padding: $font_s;
            margin-bottom: 14px;
            &_left {
              display: flex;
              align-items: center;
              width: 80%;
              &_icon{
                border-radius: 50%;
                background-color: #F7F7F8;
                padding: 5px;
              }
              h3 {
                font-size: $font_s;
                color: $third_text;
                text-align: left;
                padding-left: 10px;
                overflow: auto;
              }
            }
            &_right {
              width: 10%;
              @include flexCenterBetween;
              position: relative;
              .share {
                width: $font_s;
                height: $font_s;
                cursor: pointer;
              }
              .trash {
                width: $font_s;
                height: $font_s;
                cursor: pointer;
              }
              .tooltip {
                position: absolute;
                top: calc(100% + $pixel_5);
                left: 0%;
                transform: translateX(-50%);
                background-color: rgba(0, 0, 0, 0.8);
                color: #fff;
                padding: $pixel_5;
                border-radius: $pixel_5;
                font-size: $font_xs;
                visibility: hidden;
                opacity: 0;
                transition: opacity 0.3s ease;
                width: max-content;
                text-align: center;
              }
              .share:hover + .tooltip {
                visibility: visible;
                opacity: 1;
              }
            }
          }
        }
      }
      .line{
        border-left: 1px solid #343541;
        opacity: 0.6;
      }
      &_right {
        width: 46%;
        &_text{
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-bottom: 10px;
          h3{
            font-size: 16px;
            font-weight: 500;
            color: #343541;
          }
        }
        &_input {
            width: 100%;
            margin-bottom: 30px;
            @include flexcolumn;
            label {
              font-size: 16px;
              color: #343541;
              font-weight: 500;
              padding-bottom: 10px;
            }
            input {
              border: none;
              padding: $font_xs;
              color: $secondary_grey;
              border-radius: $border_xs;
              background-color: #FFF;
              font-size: $font_s;
              font-weight: 500;
              box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
            }
            input:focus {
              outline: none;
              border: none;
            }
          }
        &_buttons {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: $font_s;
          .cancel {
            border: $pixel_1 solid $secondary_border;
            margin: $font_xs 0;
            padding: $border_s 0;
            border-radius: $border_xs;
            font-size: $font_sm;
            font-weight: 600;
            letter-spacing: 0.2px;
            text-align: center;
            cursor: pointer;
            color: $secondary_grey;
            width: 45%;
            background-color: $primary_background;
            transition: transform 0.1s ease;
          }
          .cancel:active {
            transform: scale(0.95);
          }
          .save {
            width: 45%;
            margin: $font_xs 0;
            padding: $font_xxs 0;
            border-radius: $border_xs;
            border: none;
            font-size: $font_sm;
            font-weight: 600;
            letter-spacing: 0.2px;
            text-align: center;
            cursor: pointer;
            color: $primary_white;
            background: $primary_linear;
            opacity: 1;
            transition: transform 0.1s ease;
          }
          .save:active {
            transform: scale(0.95);
          }
        }
        &_recent{
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 20px 0 10px 0;
          border-top: 1px solid #343541;
          opacity: 0.6;
          h3{
            color: #343541;
            opacity: 0.8;
            font-weight: 500;
            font-size: 12px;
            padding-left: 5px;
          }
        }
        &_content {
          width: 100%;
          @include flexColCenter;
          border-radius: $border_xs;
          // background-color: $primary_white;
          overflow-y: auto;
          gap: 15px;
          &_print {
            width: 100%;
            @include flexCenterBetween;
            border: 1px solid $third_text;
            border-radius: $border_sm;
            padding: $font_s;
            background-color: #FFF;
            &_left {
              display: flex;
              align-items: center;
              width: 75%;
              &_icon{
                border-radius: 50%;
                background-color: #F7F7F8;
                padding: 5px;
              }
              h3 {
                font-size: $font_s;
                color: $third_text;
                text-align: left;
                overflow: auto;
                margin-left: 5px;
              }
            }
            &_right {
              width: 25%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              position: relative;
              h3 {
                font-size: $font_s;
                color: $third_text;
                text-align: left;
                overflow: auto;
              }
            }
          }
        }
      }
    }
  }
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
  }
}

@media screen and (min-width: 280px) and (max-width: 759px) {
  .upload {
    height: calc(100vh - $pixel_48);
    background-color: $primary_background;
    &_section {
      // margin: 20px 0 0 0;
      width: 100%;
      padding: 0 $font_sm;
      &_center {
        width: 100%;
        padding: 5px;
        flex-direction: column;
        &_left {
          width: 100%;
          &_text{
            h3{
              font-size: 18px;
            }
          }
          &_file {
            &_choose {
              height: 120px;
              h3 {
                font-size: $font_sm;
              }
            }
          }
          &_buttons {
            margin-bottom: 0;
          }
          &_recent{
            border-top: none;
            padding: 10px 0;
          }
        }
        .line{
          border-bottom: 1px solid #343541;
          opacity: 0.6;
          margin: 20px 0;
        }
        &_right {
          width: 100%;
          &_text{
            h3{
              font-size: 18px;
            }
          }
          &_input {
            margin-bottom: 10px;
          }
          &_buttons {
            margin-bottom: 0;
          }
          &_recent{
            border-top: none;
            padding: 10px 0;
          }
        }
      }
    }
    .loader-container {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

@media screen and (min-width: 760px) and (max-width: 1280px) {
  // .upload{
  //   height: calc(100vh - 62px);
  //   align-items: center;
  //   display: flex;
  //   justify-content: center;
  // }
}

@media screen and (min-width: 1281px) and (max-width: 1360px) {
  .upload{
    height: calc(100vh - 62px);
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1361px) and (max-width: 1440px) {
  .upload{
    height: calc(100vh - 62px);
    align-items: center;
    display: flex;
  }
}

@media screen and (min-width: 1441px) and (max-width: 2560px) {
  .upload{
    height: calc(100vh - 62px);
    align-items: center;
    display: flex;
  }
}
