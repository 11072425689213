@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.pp{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $primary_background;
    &_container{
        width: 80%;
        padding: $border_xxl 0;
        height: 100vh;
        overflow-y: auto;
    }
    h3{
        font-weight: 600;
        color: $primary_black;
    }
    h5{
        font-weight: 500;
        color: $primary_black;
        margin-top: $font_s;
    }
    li{
        padding: 0 $border_xxxl;
    }
    &_back{
        width: 100%;
        display: flex;
        justify-content: center;
        button {
            width: 15%;
            margin: $font_xs 0;
            padding: $font_xxs 0;
            border-radius: $border_xs;
            border: none;
            font-size: $font_sm;
            font-weight: 600;
            letter-spacing: 0.2px;
            text-align: center;
            cursor: pointer;
            color: $primary_white;
            background: $primary_linear;
            box-shadow: $primary_boxshadow;
            transition: transform 0.1s ease;
          }
          button:active {
            transform: scale(0.95);
          }
      }
}

@media screen and (min-width: 280px) and (max-width:768px) {
    .pp {
      &_container {
        width: 90%;
      }
      h5 {
        text-align: justify;
      }
      p{
        text-align: justify;
      }
      li {
        padding: 0;
        text-align: justify;
      }
      &_back {
        button {
          width: 25%;
        }
      }
    }
  }